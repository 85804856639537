import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from 'twin.macro'
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Banner from "../components/common/Banner"

const StyledMainWrapper = styled.section`
${tw`md:(pt-8) pt-0 bg-primary bg-opacity-5`}
.container {
    ${tw`flex flex-col items-center justify-center container mx-auto md:(px-8 py-12) px-5 py-6`};
    p {
      ${tw`md:text-lg text-base font-medium text-muted pb-6 leading-normal`}
    }
    h3 {
        ${tw`text-xl text-secondary font-semibold pb-2`}
    }
    h4 {
        ${tw`text-lg text-orange font-bold pb-2`}
    }
    h5 {
        ${tw`text-lg text-primary-dark font-medium pb-2`}
    }
    ol, ul {
      li {
          ${tw`md:text-lg text-base font-medium text-muted leading-normal`}
          &::marker {
              ${tw`text-lg font-bold text-primary-dark`}
          }
      }
  }
  ol {
    ${tw`list-decimal pl-6`}
    li {
      ${tw`pb-2`}
      p {
        ${tw`pb-2`}
      }

      ul {
          ${tw`pl-4`}
          li {
              &::marker {
                  ${tw`text-lg font-semibold text-primary-dark [content: "> "]`}
              }
          }
      }
    }
  }
}
`;
const HospitalityServiceDetails = ({ data }) => {
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Our Services', link: '/our-services' },
    { name: 'Hotel Consultancy', link: '/our-service/hotel-consultancy' },
    { name: frontmatter.title, link: '' }
  ]
  const imgPath = process.env.GATSBY_IMAGE_URL
  return (
    <Layout>
      <Banner
        data={frontmatter.title}
        image={`${imgPath}${frontmatter.slug.toLowerCase()}.jpg`}
        crumbs={crumbs}
      />
      <StyledMainWrapper>
        <div className="container">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </StyledMainWrapper>
    </Layout>
  )
};

export default HospitalityServiceDetails

export const Head = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <SEO title={frontmatter.title} description={frontmatter.description} />
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        bannerImage {
          publicURL
        }
      }
    }
  }
`